<template>
	<el-dialog title="编辑框架协议" v-model="frameDialogVisible" width="40%" center :close-on-click-modal = "false" @close='closecallinfo'>
		<div class="ucPanel" style="height: 668px;">
			<div class="ucPanel bgGrayBox">
				<div class="ucForm ucForm-lump auto-d3 nameLong" >
					<div class="ucFrom-row" style="margin-bottom: 20px;">
					<!-- <div class="ucForm-group-ad">		
						<div class="ucForm-group">
							<label class="ucForm-label">合同编号</label>
							<input name="wholesalePrice" type="text" v-model="cno" :disabled="true" class="ucForm-control">
						</div>
					</div> -->
					<div class="ucForm-group-ad">		
						<div class="ucForm-group">
							<label class="ucForm-label">合同名称</label>
							<input name="wholesalePrice" type="text" v-model="cname" class="ucForm-control">
						</div>
					</div>
					<div class="ucForm-group-ad">		
						<div class="ucForm-group">
							<label class="ucForm-label">签订时间</label>
							<el-config-provider :locale="locale">
								<el-date-picker v-model="signDate" type="date" value-format="YYYY-MM-DD" ></el-date-picker>
							</el-config-provider>
						</div>
					</div>
					<div class="ucForm-group-ad">		
						<div class="ucForm-group">
							<label class="ucForm-label">合同金额/元</label>
							<input name="wholesalePrice" type="number" v-model="amount" class="ucForm-control">
						</div>
					</div>
					<!-- <div class="ucForm-group-ad auto-d100">
						<div class="ucForm-group">
							<label class="ucForm-label">内容备注</label>
							<textarea name="wholesalePrice" type="text" v-model="mark" :disabled="viewOnly" class="ucForm-control"></textarea>
						</div>
					</div> -->
					</div>
					<div v-if="viewOnly == false" class="ucForm-group-ad ucForm-group-btn auto-d100">		
						<div class="ucForm-group ucBtn-bg">
							<button class="ucBtn ucBtn-ok ucBtn-green" v-on:click="commit"><i class="iImg"></i>保存</button>
							<button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closecallinfo"><i class="iImg"></i>清空</button>
						</div>
					</div>
					<div class="clear"></div>
				</div>
			</div>
		</div>	
			
	</el-dialog>
</template>

<script>
	import {ElConfigProvider} from 'element-plus';
	import zhCn from 'element-plus/lib/locale/lang/zh-cn';
	export default {
		name: 'ZhProvider',
		components:{
			[ElConfigProvider.name]: ElConfigProvider
		},
		props: {
			msg: String
		},
		setup(){
			let locale = zhCn
			return{
				locale
			}
		},
		data: function() {
			return {
				viewOnly: false,
				frameDialogVisible:false,                                               
				id: this.$parent.editId,
				cno:this.$parent.cno,
				cid:this.$parent.id,
				cname:this.$parent.fcname,
				gName:this.$parent.gName,
				signDate:this.$parent.fsignDate,
				amount:this.$parent.frameAmount,
				paydate:this.$parent.paydate,
				mark:'',
				ctype:this.$parent.ctype,
				mod:this.$parent.infoMod
			}
		},
		created() {
			this.frameDialogVisible = true;
		},
		methods: {
			commit: function() {
				var self = this;
				var req = {};
				if(this.amount==0||this.amount==null){
					this.$message({
						message:'请输入金额',
						type:'error'
					})
					return;
				}
				if(this.cid==''||this.cid==null){
					this.$message({
						message:'请保存合同后再添加框架协议',
						type:'error'
					})
					return;
				}
				if (this.mod == 'edit') {
					req.cmd = "updateFrame";
					req.id = self.id;
				} else {
					req.cmd = "insertFrame";
				}
				req.cid=this.cid;
				req.cname=this.cname;
				req.amount=this.amount;
				req.signDate=this.signDate;
				this.$sknet.postJson(this.$skconf("wyvc.surl"), req, function(res) {
					if(res.result=='OK'){
						this.$message({
							message:'保存成功',
							type:'success'
						})
					}else{
						this.$message({
							message:'保存失败',
							type:'error'
						})
					}
					this.frameDialogVisible = true;
					this.$emit('closeframeinfo'); //通知父组件改变。
				}, null, this);
			},
			closecallinfo: function() {
				this.frameDialogVisible = true;
				this.$emit('closeframeinfo'); //通知父组件改变。
			}
		},
		mounted() {
			if (this.mod == 'add') {
				this.viewOnly = false;
			}
			//设置 viewOnly
			if (this.mod == 'edit') {
				this.viewOnly = false;
				// this.fetch();
			}
		},
		components: {
			
		}
	}
</script>

<style scoped>

</style>
