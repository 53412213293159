<template>
	<el-dialog title="合同详情" v-model="centerDialogVisible" width="70%" center :close-on-click-modal = "false" @close='closecallinfo'>
		<div class="ucPanel" style="height: 668px;">
			<el-tabs v-model="activeName" @tab-click="handleClick" >
			    <el-tab-pane label="&nbsp;&nbsp;&nbsp;&nbsp;合同详情&nbsp;&nbsp;&nbsp;&nbsp;" name="first" >
					<div class="ucPanel bgGrayBox">
						<div class="ucForm ucForm-lump auto-d3 nameLong" >
							<div class="ucFrom-row" style="margin-bottom: 20px;">
							<div class="ucForm-group-ad">
								<div class="ucForm-group">
									<label class="ucForm-label">合同类型</label>
									<select v-model="ctype" @change="htlx()"  class="ucForm-control">
										<option v-for="option in options" v-bind:key="option.value" :label="option.text" :value="option.value">
											{{option.text}}
										</option>
									</select>
								</div>
							</div>	
							<div class="ucForm-group-ad">		
								<div class="ucForm-group">
									<label class="ucForm-label">合同名称<span class="fontRed">*</span></label>
									<input name="wholesalePrice" type="text" v-model="cname" :disabled="viewOnly" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad">		
								<div class="ucForm-group">
									<label class="ucForm-label">合同编号<span class="fontRed">*</span></label>
									<input name="wholesalePrice" type="text" v-model="cno" :disabled="viewOnly" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad">			
								<div class="ucForm-group">
									<label class="ucForm-label">合同签订部门<span class="fontRed">*</span></label>
									<select v-model="gid" class="ucForm-control">
										<option v-for="option in departments" v-bind:key="option.id" :value="option.id">
											{{option.name}}
										</option>
									</select>
								</div>
							</div>
							<div class="ucForm-group-ad">		
								<div class="ucForm-group">
									<label class="ucForm-label">申请时间<span class="fontRed">*</span></label>
									<el-config-provider :locale="locale">
										<el-date-picker v-model="applyDate" type="date" value-format="YYYY-MM-DD"></el-date-picker>
									</el-config-provider>
								</div>
							</div>
							<div class="ucForm-group-ad">		
								<div class="ucForm-group">
									<label class="ucForm-label">签订时间<span class="fontRed">*</span></label>
									<el-config-provider :locale="locale">
										<el-date-picker v-model="signDate" type="date" value-format="YYYY-MM-DD" ></el-date-picker>
									</el-config-provider>
								</div>
							</div>
							<div class="ucForm-group-ad">		
								<div class="ucForm-group">
									<label class="ucForm-label">合同金额/元<span class="fontRed">*</span></label>
									<input name="wholesalePrice" type="number" v-model="amount" :disabled="amountOnly" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad auto-d100">		
								<div class="ucForm-group">
									<label class="ucForm-label">项目类别<span class="fontRed">*</span></label>
									<el-select v-model="xmlb" multiple >
										<el-option v-for="option in xmlbs" v-bind:key="option.id" :label="option.name" :value="option.id">
											{{option.name}}
										</el-option>
									</el-select>
								</div>
							</div>
							<div class="ucForm-group-ad auto-d100">		
								<div class="ucForm-group">
									<label class="ucForm-label">专业<span class="fontRed">*</span></label>
									<el-select v-model="zy" multiple>
										<el-option v-for="option in zys" v-bind:key="option.id" :label="option.name" :value="option.id">
											{{option.name}}
										</el-option>
									</el-select>
								</div>
							</div>
							<div class="ucForm-group-ad">		
								<div class="ucForm-group">
									<label class="ucForm-label">合同状态<span class="fontRed">*</span></label>
									<select v-model="stat" class="ucForm-control">
										<option v-for="option in stats" v-bind:key="option.id" :value="option.id">
											{{option.name}}
										</option>
									</select>
								</div>
							</div>
							
							<div class="ucForm-group-ad" v-show="cght">
								<div class="ucForm-group">
									<label class="ucForm-label">供应商名称<span class="fontRed">*</span></label>
									<input name="wholesalePrice" type="text" v-model="gysmc" :disabled="viewOnly" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad" v-show="cght">
								<div class="ucForm-group">
									<label class="ucForm-label">联系人<span class="fontRed">*</span></label>
									<input name="wholesalePrice" type="text" v-model="gyslxr" :disabled="viewOnly" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad" v-show="cght">
								<div class="ucForm-group">
									<label class="ucForm-label">联系电话<span class="fontRed">*</span></label>
									<input name="wholesalePrice" type="text" v-model="gyslxdh" :disabled="viewOnly" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad" v-show="cght">
								<div class="ucForm-group">
									<label class="ucForm-label">资质等级</label>
									<input name="wholesalePrice" type="text" v-model="gyszzdj" :disabled="viewOnly" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad" style="width:66.66%" v-show="cght">
								<div class="ucForm-group">
									<label class="ucForm-label">供应商地址</label>
									<input name="wholesalePrice" type="text" v-model="gysdz" :disabled="viewOnly" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad" v-show="cght">
								<div class="ucForm-group">
									<label class="ucForm-label">主体合同名称</label>
									<input name="wholesalePrice" type="text" v-model="zcname" :disabled="true" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad" v-show="cght">
								<div class="ucForm-group">
									<label class="ucForm-label">主体合同编号</label>
									<input name="wholesalePrice" style="width: 55%;" @click="selectContract" type="button" v-model="zcno" :disabled="true" class="ucForm-control">
									<button class="ucBtn ucBtn-info ucBtn-green" @click="selectContract">选择</button>
									<!-- <select v-model="zthtid" class="ucForm-control" @change="contractChange">
										<option v-for="option in contractList" v-bind:key="option.id" :value="option.id">
											{{option.cno}}
										</option>
									</select> -->
								</div>
							</div>
							<div class="ucForm-group-ad" v-show="cght">
								<div class="ucForm-group">
									<label class="ucForm-label">主体合同金额/元</label>
									<input name="wholesalePrice" type="text" v-model="zamount" :disabled="true" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad auto-d100" v-show="cght">		
								<div class="ucForm-group">
									<label class="ucForm-label">采购内容<span class="fontRed">*</span></label>
									<textarea name="wholesalePrice" type="text" v-model="cgnr" :disabled="viewOnly" class="ucForm-control"></textarea>
								</div>
							</div>
							<div class="ucForm-group-ad auto-d100" v-show="cght">		
								<div class="ucForm-group">
									<label class="ucForm-label">技术要求</label>
									<textarea name="wholesalePrice" type="text" v-model="jsyq" :disabled="viewOnly" class="ucForm-control"></textarea>
								</div>
							</div>
							
							
							<div class="ucForm-group-ad" v-show="fwht">		
								<div class="ucForm-group">
									<label class="ucForm-label">项目负责人<span class="fontRed">*</span></label>
									<input name="wholesalePrice" type="text" v-model="xmfzr" :disabled="viewOnly" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad" v-show="fwht">		
								<div class="ucForm-group">
									<label class="ucForm-label">项目地点<span class="fontRed">*</span></label>
									<input name="wholesalePrice" type="text" v-model="xmdd" :disabled="viewOnly" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad" v-show="fwht">		
								<div class="ucForm-group">
									<label class="ucForm-label">工程投资</label>
									<input name="wholesalePrice" type="text" v-model="gctz" :disabled="viewOnly" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad" v-show="fwht">		
								<div class="ucForm-group">
									<label class="ucForm-label">费率</label>
									<input name="wholesalePrice" type="text" v-model="fl" :disabled="viewOnly" class="ucForm-control">
								</div>
							</div>
							<div class="ucForm-group-ad" v-show="fwht">
								<div class="ucForm-group">
									<label class="ucForm-label">是否获奖</label>
									<select v-model="isaward" class="ucForm-control" @change="award()">
										<option v-for="option in isawards" v-bind:key="option.value" :value="option.value">
											{{option.text}}
										</option>
									</select>
								</div>
							</div>
							
							</div>
							
							<div class="ucFrom-row" style="margin-bottom: 20px;" v-show="fwht">
								<div class="ucForm-group-ad">		
									<div class="ucForm-group">
										<label class="ucForm-label">建设单位<span class="fontRed">*</span></label>
										<input name="wholesalePrice" type="text" v-model="jsdw" :disabled="viewOnly" class="ucForm-control">
									</div>
								</div>
								<div class="ucForm-group-ad">		
									<div class="ucForm-group">
										<label class="ucForm-label">联系人<span class="fontRed">*</span></label>
										<input name="wholesalePrice" type="text" v-model="lxr" :disabled="viewOnly" class="ucForm-control">
									</div>
								</div>
								<div class="ucForm-group-ad">		
									<div class="ucForm-group">
										<label class="ucForm-label">联系电话<span class="fontRed">*</span></label>
										<input name="wholesalePrice" type="text" v-model="lxdh" :disabled="viewOnly" class="ucForm-control">
									</div>
								</div>
								<div class="ucForm-group-ad auto-d100">		
									<div class="ucForm-group">
										<label class="ucForm-label">建设单位地址<span class="fontRed">*</span></label>
										<input name="wholesalePrice" type="text" v-model="jsdwdz" :disabled="viewOnly" class="ucForm-control">
									</div>
								</div>
							</div>
							<div class="ucFrom-row" v-show="fwht">
								<div class="ucFrom-row">
									<div class="ucForm-group-ad">		
										<div class="ucForm-group">
											<label class="ucForm-label">是否是框架协议</label>
											<select v-model="isframe" class="ucForm-control" @change="frame()">
												<option v-for="option in isframes" v-bind:key="option.value" :value="option.value">
													{{option.text}}
												</option>
											</select>
										</div>
									</div>
									<div class="ucForm-group-ad">		
										<div class="ucForm-group">
											<label class="ucForm-label">项目来源</label>
											<input name="wholesalePrice" type="text" v-model="xmly" :disabled="viewOnly" class="ucForm-control">
										</div>
									</div>
									<div class="ucForm-group-ad">		
										<div class="ucForm-group">
											<label class="ucForm-label">是否联合体</label>
											<select v-model="sflht" class="ucForm-control">
												<option v-for="option in sflhts" v-bind:key="option.value" :value="option.value">
													{{option.text}}
												</option>
											</select>
										</div>
									</div>
								</div>
								<div class="ucForm-group-ad">	
									<div class="ucForm-group">
										<label class="ucForm-label">联合体单位</label>
										<input name="wholesalePrice" type="text" v-model="lhtdw" :disabled="viewOnly" class="ucForm-control">
									</div>
								</div>
								<div class="ucForm-group-ad">		
									<div class="ucForm-group">
										<label class="ucForm-label">联系人</label>
										<input name="wholesalePrice" type="text" v-model="lhtlxr" :disabled="viewOnly" class="ucForm-control">
									</div>
								</div>
								<div class="ucForm-group-ad">		
									<div class="ucForm-group">
										<label class="ucForm-label">联系电话</label>
										<input name="wholesalePrice" type="text" v-model="lhtlxdh" :disabled="viewOnly" class="ucForm-control">
									</div>
								</div>
								<div class="ucForm-group-ad auto-d100">		
									<div class="ucForm-group">
										<label class="ucForm-label">联合体单位地址</label>
										<input name="wholesalePrice" type="text" v-model="lhtdwdz" :disabled="viewOnly" class="ucForm-control">
									</div>
								</div>
								<div class="ucForm-group-ad auto-d100">		
									<div class="ucForm-group">
										<label class="ucForm-label">内容备注</label>
										<textarea name="wholesalePrice" type="text" v-model="mark" :disabled="viewOnly" class="ucForm-control"></textarea>
									</div>
								</div>
							</div>
							<div v-show="viewOnly == false" class="ucForm-group-ad ucForm-group-btn auto-d100">		
								
									<div class="ucForm-group ucBtn-bg">
										<button class="ucBtn ucBtn-ok ucBtn-green" v-on:click="commit"><i class="iImg"></i>保存</button>
										<button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closecallinfo"><i class="iImg"></i>清空</button>
										<!-- <button class="ucBtn ucBtn-close ucBtn-gray" v-on:click="closecallinfo"><i class="iImg"></i>关闭</button> -->
									</div>
								
							</div>
							<div class="clear"></div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="&nbsp;&nbsp;&nbsp;&nbsp;框架协议&nbsp;&nbsp;&nbsp;&nbsp;" name="second" v-if="isShowFrame">
					<div class="ctms_tabR ucBtn-bg"><button class="ucBtn ucBtn-add ucBtn-green"  @click="handleClickAdd()"><i class="iImg"></i>添加</button></div>
					<div class="ucPanel" style="position: relative;">
						<div class="ucPanel ucPanel-table">
							<el-table :data="frameList" highlight-current-row border style="width: 100%">
								<el-table-column prop="cname" label="合同名称" ></el-table-column>
								<el-table-column prop="amount" label="合同金额/元" ></el-table-column>
								<el-table-column prop="creatorName" label="创建人" ></el-table-column>
								<el-table-column prop="signDate" label="签订时间" ></el-table-column>
								<el-table-column fixed="right" label="操作" width="180px" >
									<template v-slot="scope" >
										<button class="ucBtn ucBtn-info ucBtn-green" @click="handleClickEdit(scope.row)"><i class="iImg"></i>修改</button>
										<button class="ucBtn ucBtn-info ucBtn-green" @click="handleClickDelete(scope.row)"><i class="iImg"></i>删除</button>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="&nbsp;&nbsp;&nbsp;&nbsp;获奖记录&nbsp;&nbsp;&nbsp;&nbsp;" name="third" v-if="isShowAward">
					<div class="ctms_tabR ucBtn-bg"><button class="ucBtn ucBtn-add ucBtn-green"  @click="handleClickAddAward()"><i class="iImg"></i>添加</button></div>
					<div class="ucPanel" style="position: relative;">
						<div class="ucPanel ucPanel-table">
							<el-table :data="awardList" highlight-current-row border style="width: 100%">
								<el-table-column prop="awardLevel" label="获奖级别"></el-table-column>
								<el-table-column prop="awardPerson" label="获奖人" ></el-table-column>
								<el-table-column prop="awardDate" label="获奖时间" ></el-table-column>
								<el-table-column prop="mark" label="备注" ></el-table-column>
								<el-table-column fixed="right" label="操作" width="180px" >
									<template v-slot="scope" >
										<button class="ucBtn ucBtn-info ucBtn-green" @click="handleClickEditAward(scope.row)"><i class="iImg"></i>修改</button>
										<button class="ucBtn ucBtn-info ucBtn-green" @click="handleClickDeleteAward(scope.row)"><i class="iImg"></i>删除</button>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>	
			
	</el-dialog>
		<ctms_contract_frame_info @closeframeinfo="closeframeinfo" @frameCommit="frameCommit" append-to-body="true" v-if="frameDialogVisible" width="70%" ></ctms_contract_frame_info>
		<ctms_contract_award_info @closeawardinfo="closeawardinfo" append-to-body="true" v-if="awardDialogVisible" width="70%" ></ctms_contract_award_info>
		<ctms_select_contract_list @closecontractinfo="closecontractinfo" @selectcontractinfo="selectcontractinfo" append-to-body="true" v-if="contractDialogVisible" width="70%" ></ctms_select_contract_list>
</template>

<script>
	import ctms_contract_frame_info from './ctms_contract_frame_info.vue'
	import ctms_contract_award_info from './ctms_contract_award_info.vue'
	import ctms_select_contract_list from './ctms_select_contract_list.vue'
	import {ElConfigProvider} from 'element-plus';
	import zhCn from 'element-plus/lib/locale/lang/zh-cn';
	export default {
		name: 'ZhProvider',
		props: {
			msg: String
		},
		components:{
			[ElConfigProvider.name]: ElConfigProvider
		},
		setup(){
			let locale = zhCn
			return{
				locale
			}
		},
		data: function() {
			return {
				viewOnly: false,
				amountOnly:false,
				frameDialogVisible: false,
				awardDialogVisible:false,
				contractDialogVisible:false,
				isShowAward:false,
				id: this.$parent.editId,
				mod: this.$parent.infoMod,
				editDisable: false,
				name:"",
				selected: '',
				options: [
					{ text: '服务合同', value: '1' },
					{ text: '采购合同', value: '2' }
				],
				sqsj:'',
				qdsj:'',
				percentage:20,
				activeName: 'first',
				dataList:[{ id: 1, cjdw: 'xxxxxxxxxxxxxx', htje: 'xxxxxxxxxxxxxx', cgrq: 'xxxxxxxxxxxxxx'}],
				dataList2:[{ id: 1, skje: 'xxxxxxxxxxxxxx', sksj: 'xxxxxxxxxxxxxx', czr: 'xxxxxxxxxxxxxx', bz: 'xxxxxxxxxxxxxx'}],
				cght:false,
				fwht:true,
				ctype:1,
				departments:[],
				xmlbs:[],
				zys:null,
				gid:'',
				xmlb:'',
				zy:'',
				cname:'',
				cno:'',
				applyDate:'',
				signDate:'',
				amount:'',
				xmfzr:'',
				xmdd:'',
				gctz:'',
				jsdw:'',
				lxr:'',
				lxdh:'',
				jsdwdz:'',
				xmly:'',
				sflht:'',
				lhtdw:'',
				lhtlxr:'',
				lhtlxdh:'',
				lhtdwdz:'',
				mark:'',
				gcjd:'',
				gysmc:'',
				gyslxr:'',
				gyslxdh:'',
				gyszzdj:'',
				gysdz:'',
				cgnr:'',
				jsyq:'',
				sflhts: [
					{ text: '是', value: '1' },
					{ text: '否', value: '2' }
				],
				zthtid:'',
				isframes: [
					{ text: '是', value: '1' },
					{ text: '否', value: '2' }
				],
				isawards: [
					{ text: '是', value: '1' },
					{ text: '否', value: '2' }
				],
				isframe:2,
				isaward:2,
				frameList:[],
				awardList:[],
				isShowFrame:false,
				contractFrame:0,
				frameAmount:0,
				editId:null,
				fl:'',
				stats: [
					{ name: '正常', id: '1' },
					{ name: '作废', id: '2' }
				],
				stat:'',
				fsignDate:'',
				fcname:'',
				contractList:[],
				awardPerson:'',
				awardLevel:'',
				awardDate:'',
				amark:'',
				contractItem:{},
				zcno:''
			}
		},
		created() {
			this.centerDialogVisible = true;
			this.searchDepartment();
			this.searchMajors();
			// this.getXmfzr();
			this.getType();
			this.fetch();
			// this.xzselect();
		},
		methods: {
			handleClickEdit: function(data){
				this.infoMod = "edit";
				this.frameDialogVisible = true;
				this.frameAmount=data.amount;
				this.editId=data.id;
				this.fsignDate=data.signDate;
				this.fcname=data.cname;
			},
			handleClickDelete: function(data){
				this.$confirm('您确定要这条框架协议吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'}).then(()=>{
						this.deleteData(data.id);
				});
			},
			deleteData: function(id){
				this.dataList = [];
				var req = {};
				req.cmd = "deleteFrame";
				req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					this.fetch();
				}, null, this);	
			},
			handleClickEditAward: function(data){
				this.infoMod = "edit";
				this.awardDialogVisible = true;
				this.awardPerson=data.awardPerson;
				this.editId=data.id;
				this.awardLevel=data.awardLevelCode;
				this.awardDate=data.awardDate;
				this.amark=data.mark;
			},
			handleClickDeleteAward: function(data){
				this.$confirm('您确定要这条获奖记录吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'}).then(()=>{
						this.deleteDataAward(data.id);
				});
			},
			deleteDataAward: function(id){
				this.dataList = [];
				var req = {};
				req.cmd = "deleteAward";
				req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					this.fetch();
				}, null, this);	
			},
			handleClickAdd: function(){
				this.infoMod = "add";
				this.frameDialogVisible = true;
			},
			handleClickAddAward: function(){
				this.infoMod = "add";
				this.awardDialogVisible = true;
			},
			commit: function() {
				var self = this;
				var req = {};
				if (this.mod == 'edit') {
					req.cmd = "updateContract";
					req.id = self.id;
				} else {
					req.cmd = "insertContract";
				}
				if(this.cname==null||this.cname==''){
					self.$message({message:"合同名称不能为空",type:'error'});
					return;
				}
				if(this.cno==null||this.cno==''){
					self.$message({message:"合同编号不能为空",type:'error'});
					return;
				}
				if(this.gid==null||this.gid==''){
					self.$message({message:"签订部门不能为空",type:'error'});
					return;
				}
				if(this.applyDate==null||this.applyDate==''){
					self.$message({message:"申请时间不能为空",type:'error'});
					return;
				}
				if(this.signDate==null||this.signDate==''){
					self.$message({message:"签订时间不能为空",type:'error'});
					return;
				}
				if(this.amount==null||this.amount==''){
					self.$message({message:"合同金额不能为空",type:'error'});
					return;
				}
				if(this.xmlb==null||this.xmlb==''){
					self.$message({message:"项目类别不能为空",type:'error'});
					return;
				}
				if(this.zy==null||this.zy==''){
					self.$message({message:"专业不能为空",type:'error'});
					return;
				}
				if(this.stat==null||this.stat==''){
					self.$message({message:"合同状态不能为空",type:'error'});
					return;
				}
				if(this.ctype==1||this.ctype=='1'){
					if(this.xmfzr==null||this.xmfzr==''){
						self.$message({message:"项目负责人不能为空",type:'error'});
						return;
					}
					if(this.xmdd==null||this.xmdd==''){
						self.$message({message:"项目地点不能为空",type:'error'});
						return;
					}
					if(this.jsdw==null||this.jsdw==''){
						self.$message({message:"建设单位不能为空",type:'error'});
						return;
					}
					if(this.lxr==null||this.lxr==''){
						self.$message({message:"联系人不能为空",type:'error'});
						return;
					}
					if(this.lxdh==null||this.lxdh==''){
						self.$message({message:"联系电话不能为空",type:'error'});
						return;
					}
					if(this.jsdwdz==null||this.jsdwdz==''){
						self.$message({message:"建设单位地址不能为空",type:'error'});
						return;
					}
				}else{
					if(this.gysmc==null||this.gysmc==''){
						self.$message({message:"供应商名称不能为空",type:'error'});
						return;
					}
					if(this.gyslxr==null||this.gyslxr==''){
						self.$message({message:"联系人不能为空",type:'error'});
						return;
					}
					if(this.gyslxdh==null||this.gyslxdh==''){
						self.$message({message:"联系电话不能为空",type:'error'});
						return;
					}
					if(this.cgnr==null||this.cgnr==''){
						self.$message({message:"采购内容不能为空",type:'error'});
						return;
					}
				}
				req.gid=this.gid;
				req.ctype=this.ctype;
				req.cname=this.cname;
				req.cno=this.cno;
				req.stat=1;
				req.zthtid=this.zthtid;
				req.applyDate=this.applyDate;
				req.signDate=this.signDate;
				req.amount=this.amount;
				req.xmlb = this.xmlb.join(',');
				req.zy = this.zy.join(',');
				req.xmfzr=this.xmfzr;
				req.xmdd=this.xmdd;
				req.gctz=this.gctz;
				req.jsdw=this.jsdw;
				req.lxr=this.lxr;
				req.lxdh=this.lxdh;
				req.jsdwdz=this.jsdwdz;
				req.xmly=this.xmly;
				req.sflht=this.sflht;
				req.lhtdw=this.lhtdw;
				req.lhtlxr=this.lhtlxr;
				req.lhtlxdh=this.lhtlxdh;
				req.lhtdwdz=this.lhtdwdz;
				req.mark=this.mark;
				req.gcjd=0;
				req.gysmc=this.gysmc;
				req.gyslxr=this.gyslxr;
				req.gyslxdh=this.gyslxdh;
				req.gyszzdj=this.gyszzdj;
				req.gysdz=this.gysdz;
				req.cgnr=this.cgnr;
				req.jsyq=this.jsyq;
				req.isframe=this.isframe;
				req.isaward=this.isaward;
				req.fl=this.fl;
				req.stat=this.stat;
				req.zthtId=this.zthtid;
				req.gcjd=this.gcjd;
				this.$sknet.postJson(this.$skconf("wyvc.surl"), req, function(res) {
					self.$message("操作成功");
					self.closecallinfo();
				}, null, self);
			},
			fetch: function() {
				var req = {};
				req.cmd = 'searchDetail';
				req.id = this.id;
				req.ctype = this.ctype;
				if (this.mod == 'add') {					return;
				}
				this.$sknet.postJson(this.$skconf("wyvc.surl"), req, function(res) {
					console.log(res)
					this.frameList=res.datas.frames;
					this.awardList=res.datas.awards;
					var item=res.datas.result;
					this.gid=item.gid;
					this.ctype=item.ctype;
					this.cname=item.cname;
					this.cno=item.cno;
					this.stat=item.stat;
					this.zthtid=item.zthtid;
					this.applyDate=item.applyDate;
					this.signDate=item.signDate;
					this.amount=item.amount;
					this.xmfzr=item.xmfzr;
					this.xmdd=item.xmdd;
					this.gctz=item.gctz;
					this.jsdw=item.jsdw;
					this.lxr=item.lxr;
					this.lxdh=item.lxdh;
					this.jsdwdz=item.jsdwdz;
					this.xmly=item.xmly;
					this.sflht=item.sflht;
					this.lhtdw=item.lhtdw;
					this.lhtlxr=item.lhtlxr;
					this.lhtlxdh=item.lhtlxdh;
					this.lhtdwdz=item.lhtdwdz;
					this.mark=item.mark;
					this.gcjd=item.gcjd;
					this.gysmc=item.gysmc;
					this.gyslxr=item.gyslxr;
					this.gyslxdh=item.gyslxdh;
					this.gyszzdj=item.gyszzdj;
					this.gysdz=item.gysdz;
					this.cgnr=item.cgnr;
					this.jsyq=item.jsyq;
					this.isframe=item.isframe;
					this.isaward=item.isaward;
					this.fl=item.fl;
					this.stat=item.stat;
					this.zthtid=item.zthtId;
					this.gcjd=item.gcjd;
					if(this.isframe==1){
						this.isShowFrame=true;
						this.amountOnly=true;
					}else{
						this.isShowFrame=false;
						this.amountOnly=false;
					}
					if(this.isaward==1){
						this.isShowAward=true;
					}else{
						this.isShowAward=false;
					}
					if(typeof res.datas.ztcontract!='undefined' && res.datas.ztcontract!=null&& res.datas.ztcontract!=''){
						this.zamount=res.datas.ztcontract.amount;
						this.zcname=res.datas.ztcontract.cname;
						this.zcno=res.datas.ztcontract.cno;
						this.zthtid=res.datas.ztcontract.id;
					}
					if(typeof item.xmlbid != 'undefined' && item.xmlbid!=null && item.xmlbid!=''){
						let xmlb = item.xmlbid.split(",");
						for(var i=0;i<xmlb.length;i++){
							xmlb[i]=parseInt(xmlb[i])
						}
						this.xmlb=xmlb;
					}
					if(typeof item.zyid != 'undefined' && item.zyid!=null && item.zyid!=''){
						let zy = item.zyid.split(",");
						for(var i=0;i<zy.length;i++){
							zy[i]=parseInt(zy[i])
						}
						this.zy=zy;
					}
					this.htlx();
					this.contractChange();
				}, null, this);
			},
			closecallinfo: function() {
				// this.frameDialogVisible = true;
				// console.log(this.contractFrame);
				this.centerDialogVisible = true;
				this.$emit('closecallinfo'); //通知父组件改变。
			},
			closeframeinfo: function(){
				this.frameAmount=0;
				this.editId=null;
				this.fsignDate='';
				this.fcname='';
				this.frameDialogVisible = false;
				this.getFrame();
			},
			getFrame: function(){
				var req = {};
				req.cmd = "getFrame";
				req.id = this.id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					this.frameList=res.datas.frames;
					this.amount=res.datas.amount;
				}, null, this);	
			},
			closeawardinfo: function(){
				this.editId=null;
				this.awardPerson='';
				this.awardLevel='';
				this.awardDate='';
				this.amark='';
				this.awardDialogVisible = false;
				this.getAward();
			},
			getAward: function(){
				var req = {};
				req.cmd = "searchAward";
				req.id = this.id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					this.awardList=res.datas.awards;
				}, null, this);	
			},
			frameCommit: function(contractFrame) {
				console.log(contractFrame)
				this.frameAmount=0;
				this.editId=null;
				this.fsignDate='';
				this.fcname='';
				this.frameDialogVisible = false;
			},
			htlx:function() {
				if(this.ctype==1){
					this.fwht=true;
					this.cght=false;
				}else{
					this.fwht=false;
					this.cght=true;
				}
			},
			searchMajors: function(){
				this.zy = [];
				var req = {};
				req.cmd = "searchMajors";
				// req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result.majors);
					this.zys = res.datas.result.majors;
					console.log(this.zys);
				}, null, this);	
			},
			searchDepartment:function(){
				this.departments = [];
				var req = {};
				req.cmd = "searchDepartment";
				req.type=2;
				// req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result.departments);
					this.departments = res.datas.result.departments;
				}, null, this);	
			},
			getXmfzr:function(){
				this.person = [];
				var req = {};
				req.cmd = "getXmfzr";
				// req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result.contract);
					this.person = res.datas.result.contract;
					console.log(this.person);
				}, null, this);	
			},
			getType:function(){
				this.xmlbs = [];
				var req = {};
				req.cmd = "getType";
				// req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result.majors);
					this.xmlbs = res.datas.result.majors;
					console.log(this.xmlbs);
				}, null, this);	
			},
			frame:function(){
				if(this.isframe==1){
					this.isShowFrame=true;
					this.amountOnly=true;
				}else{
					this.isShowFrame=false;
					this.amountOnly=false;
				}
			},
			award:function(){
				if(this.isaward==1){
					this.isShowAward=true;
				}else{
					this.isShowAward=false;
				}
			},
			xzselect:function() {
				this.contractList=[];
				var req = {};
				req.cmd = "searchContractByCno";
				req.ctype=1;
				req.stat=1;
				if(this.gid==""||typeof this.gid=="undefined"){
					req.gid=null;
				}else{
					req.gid=this.gid;
				}
				// req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result.contract);
					this.contractList = res.datas.result.contract;
					this.contractChange();
				}, null, this);	
			},
			selectContract:function() {
				this.contractDialogVisible=true;
			},
			selectcontractinfo:function(data){
				console.log(data);
				this.zamount=data.amount;
				this.zcname=data.cname;
				this.zcno=data.cno;
				this.zthtid=data.id;
				this.contractDialogVisible = false;
			},
			closecontractinfo:function(){
				this.contractDialogVisible = false;
			},
			contractChange:function(){
				for(var i=0;i<this.contractList.length;i++){
					if(this.contractList[i].id==this.zthtid){
						this.zcname=this.contractList[i].cname;
						this.zamount=this.contractList[i].amount;
					}
				}
			}
		},
		mounted() {
			if (this.mod == 'add') {
				this.viewOnly = false;
			}
			//设置 viewOnly
			if (this.mod == 'edit') {
				this.viewOnly = false;
				// this.fetch();
			}
			
		},
		components: {
			ctms_contract_frame_info,
			ctms_contract_award_info,
			ctms_select_contract_list
		}
	}
</script>

<style scoped>

</style>
