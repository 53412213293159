<template>
	<div v-dialogdrag>
	<el-dialog title="合同列表" v-model="centerDialogVisible" width="70%" center :close-on-click-modal = "false" @close='closecontractinfo'>
		<div class="ucPanel" style="height: 668px;">
			<div class="ucPanel bgGrayBox ctms_srh ucBtn-bg" :class="{'ctms_srhHide' : shouldshow }" >
				<div class="ucForm ucForm-flow">
					<div class="ucForm-group">
						<label  class="ucForm-label">合同编号</label>
						<input name="cno" v-model="cno" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
					</div>
					<div class="ucForm-group">
						<label  class="ucForm-label">合同名称</label>
						<input name="cname" v-model="cname" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
					</div>
				</div>
				<div class="ctms_srhBtn">
					<button class="ucBtn ucBtn-search ucBtn-green"  @click="fetch()"><i class="iImg"></i>查询</button>
				</div>
			</div>
			<div class="ucPanel ucPanel-table" >
				<el-table :data="dataList" highlight-current-row border style="width: 100%">
					<el-table-column prop="id" label="序号"  width="78px;"></el-table-column>
					<el-table-column prop="cno" label="合同编号" ></el-table-column>
					<el-table-column prop="cname" label="合同名称" ></el-table-column>
					<el-table-column prop="jsdw" label="委托单位" ></el-table-column>
					<el-table-column prop="amount" label="合同金额 / 万元" ></el-table-column>
					<el-table-column prop="signDate" label="签订日期"></el-table-column>
					<el-table-column prop="gcjd" label="工程进度"></el-table-column>
					<el-table-column prop="fkje" label="付款金额"></el-table-column>
					<el-table-column fixed="right" label="操作" width="180px" >
						<template v-slot="scope" >
							<button class="ucBtn ucBtn-info ucBtn-green" @click="handleClick(scope.row)"><i class="iImg"></i>选择</button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="currentPage"
						:page-size="pagesize" 
						layout="prev, pager, next"
						:total="total_count">   
				</el-pagination>
			</div>
		</div>	
			
	</el-dialog>
	</div>
</template>
<script>
	import ctms_contract_financial from './ctms_contract_financial.vue';
	import ctms_contract_purchase from './ctms_contract_purchase.vue';
	import ctms_contract_gcjd from './ctms_contract_gcjd.vue';
	import {ElConfigProvider} from 'element-plus';
	import zhCn from 'element-plus/lib/locale/lang/zh-cn';
	import * as echarts from 'echarts';
	import 'echarts/lib/component/tooltip';
	export default {
		name: 'ZhProvider',
		components:{
			[ElConfigProvider.name]: ElConfigProvider
		},
		setup(){
			let locale = zhCn
			return{
				locale
			}
		},
		data: function() {
			this.charts=null
			return {
				centerDialogVisible: false,
				dataList:[],
				currentPage:1, //初始页
				pagesize:10,    //    每页的数据
				total_count:0,
				cno:'',
				cname:''
			}
		},
		created() {
			this.centerDialogVisible = true;
			this.fetch();
		},
		methods: {
			fetch: function() {
				var req = {};
				req.cmd = "searchAllContractForInsert";
				req.ctype=1;
				req.start = (this.currentPage-1) * this.pagesize;
				req.count = this.pagesize;
				if(this.cno!=null&&this.cno!=''){
					req.cno=this.cno;
				}
				if(this.cname!=null&&this.cnname!=''){
					req.cname=this.cname;
				}
				this.$sknet.postJson(this.$skconf("wyvc.surl"), req, function(res) {
					console.log(res);
					this.dataList = res.datas.result.contract;
					this.total_count=res.datas.result.total_count;
				}, null, this);
			},
			closecontractinfo: function() {
				this.contractDialogVisible = true;
				this.$emit('closecontractinfo'); //通知父组件改变。
			},
			handleSizeChange: function (size) {
				this.pagesize = size;
			},
			handleCurrentChange: function(currentPage){
				this.currentPage = currentPage;
				this.fetch();
			},
			handleClick:function(data) {
				this.contractDialogVisible = true;
				this.$emit('selectcontractinfo',data); //通知父组件改变。
			}
		},
		mounted() {
			// this.$nextTick(function(){
			// 	this.drawcategory('main');
			// })
		},
		components: {
			ctms_contract_financial,
			ctms_contract_purchase,
			ctms_contract_gcjd
		}
	}
</script>

<style scoped>

</style>
