<template>
	<div class="ucPanel pageTname">合同录入</div>
	<div class="ucPanel pageTname fontGreen" style="font-weight: bold;">{{department}}</div>
	<div class="ucPanel bgGrayBox ctms_srh ucBtn-bg" :class="{'ctms_srhHide' : shouldshow }" >  <!-- 收起 添加 ctms_srhHide -->
		<div class="ctms_srhR_arr" @click="putaway">{{putawayname}}</div>
		<div class="ucForm ucForm-flow">
			<div class="ucFrom-row">
				<div class="ucForm-group">
					<label class="ucForm-label">签订时间</label>
					<el-config-provider :locale="locale">
					<el-date-picker v-model="beginDate" type="date" value-format="YYYY-MM-DD" ></el-date-picker><span style="padding: 0 15px;">-</span>
					<el-date-picker v-model="endDate" type="date" value-format="YYYY-MM-DD" ></el-date-picker>
					</el-config-provider>
				</div>
			</div>
			<div class="ucFrom-row">
			<!-- <div class="ucForm-group">
				<label class="ucForm-label">合同类型</label>
				<select v-model="ctype" class="ucForm-control" clearable="true">
					<option value=null>请选择</option>
					<option v-for="option in ctypes" v-bind:key="option.value" :value="option.value">
						{{option.ctype}}
					</option>
				</select>
			</div> -->
					
			<div class="ucForm-group">
				<label  class="ucForm-label">合同编号</label>
				<input name="cno" v-model="cno" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
					
			<div class="ucForm-group">
				<label  class="ucForm-label">合同名称</label>
				<input name="cname" v-model="cname" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
					
			<div class="ucForm-group">
				<label  class="ucForm-label">合同金额/元</label>
				<input name="amount" style="width:100px" v-model="amount" type="number" autocomplete="off" class="ucForm-control" placeholder="请输入" /><span style="padding: 0 15px;">-</span>
				<input name="amount" style="width:100px" v-model="amount2" type="number" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
					
			<div class="ucForm-group">
				<label  class="ucForm-label">项目类别</label>
				<select v-model="xmlb" class="ucForm-control">
					<option value="">请选择</option>
					<option v-for="x in xmlbs" v-bind:key = "x.name" :value="x.id">
						{{x.name}}
					</option>
				</select>
			</div>
					
			<div class="ucForm-group">
				<label class="ucForm-label">专业</label>
				<select v-model="zy" class="ucForm-control">
					<option value="">请选择</option>
					<option v-for="x in zys" v-bind:key = "x.name" :value="x.id">
						{{x.name}}
					</option>
				</select>
			</div>
					
			<div class="ucForm-group">
				<label  class="ucForm-label">委托单位</label>
				<input name="gysmc" v-model="gysmc" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
					
			<div class="ucForm-group">
				<label  class="ucForm-label">项目负责人</label>
				<input name="gysmc" v-model="xmfzr" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
					
			<div class="ucForm-group">
				<label  class="ucForm-label">工程投资</label>
				<input name="gctz" v-model="gctz" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
					
			<div class="ucForm-group">
				<label  class="ucForm-label">项目来源</label>
				<input name="xmly" v-model="xmly" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
					
			<div class="ucForm-group">
				<label  class="ucForm-label">主要内容</label>
				<input name="mark" v-model="mark" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
			
			</div>
			<div class="ucFrom-row">
				<div class="ucForm-group" style="width: 100%;">
					<label class="ucForm-label">签订部门</label>
					<el-select v-model="deptId" multiple  class="elSelectFull" >
						<el-option v-for="option in departments" :key="option.id" :label="option.name" :value="option.id">
							{{option.name}}
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="clear"></div>
		</div>
	    <div class="ctms_srhBtn">
			<button class="ucBtn ucBtn-search ucBtn-green"  @click="fetchData()"><i class="iImg"></i>查询</button>
			<button class="ucBtn ucBtn-import ucBtn-green"  @click="handleClickImport()"><i class="iImg"></i>导入</button>
			<input type="file" ref="fileRef" @change="uploadContract" id="upload_contract_file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" name="file1" style="display: none;">
			<a href="/muban.xls" id="download" class="ucBtn ucBtn-export ucBtn-green" download="导入模板.xls"><i class="iImg"></i>下载模板</a>
		</div>
	</div>
	
	<div class="ucPanel bgGrayBox" style="background-color: #fff;">
		<div class="ctms_dataSta">
			<ul>
				<li>
					<dl>
						<i class="iImg"><img src="../imgs/ctms/ctms_dataSta_img1.png"/></i>
						<dt>合同数量 (服务) / 项</dt>
						<dd>{{serviceCount}}</dd>
					</dl>
				</li>
				<li>
					<dl>
						<i class="iImg"><img src="../imgs/ctms/ctms_dataSta_img2.png"/></i>
						<dt>合同数量 (采购) / 项</dt>
						<dd>{{purchaseCount}}</dd>
					</dl>
				</li>
				<li>
					<dl>
						<i class="iImg"><img src="../imgs/ctms/ctms_dataSta_img3.png"/></i>
						<dt>合同金额 (服务) / 万元</dt>
						<dd>{{serviceContractAmount}}</dd>
					</dl>
				</li>
				<li>
					<dl>
						<i class="iImg"><img src="../imgs/ctms/ctms_dataSta_img4.png"/></i>
						<dt>合同金额 (采购) / 万元</dt>
						<dd>{{purchaseContractAmount}}</dd>
					</dl>
				</li>
			</ul>
			<div class="clear"></div>
		</div>
	</div>
	
	<div class="ucPanel" style="position: relative;">
		<div class="ctms_tabR ucBtn-bg"><button class="ucBtn ucBtn-add ucBtn-green"  @click="handleClickAdd()"><i class="iImg"></i>添加</button></div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="&nbsp;&nbsp;&nbsp;&nbsp;服务合同&nbsp;&nbsp;&nbsp;&nbsp;" name="1">
				
					<div class="ucPanel ucPanel-table">
						
						<el-table :data="dataList" highlight-current-row border style="width: 100%">
							<el-table-column type="selection" width="55px" align="center"/>
							<el-table-column label="序号"  width="78px;">
								<template v-slot="scope">
									{{ (currentPage-1)*pagesize+scope.$index+1 }}
								</template>
							</el-table-column>
							<el-table-column prop="cno" label="合同编号" ></el-table-column>
							<el-table-column prop="cname" label="合同名称" ></el-table-column>
							<el-table-column prop="jsdw" label="委托单位" ></el-table-column>
							<el-table-column prop="amount" label="合同金额/元" ></el-table-column>
							<el-table-column prop="signDate" label="签订日期"></el-table-column>
							<el-table-column prop="gcjd" label="工程进度"></el-table-column>
							<!-- <el-table-column prop="famount" label="收款金额/元"></el-table-column> -->
							<el-table-column fixed="right" label="操作" width="180px" >
								<template v-slot="scope" >
									<button class="ucBtn ucBtn-info ucBtn-green" @click="handleClickEdit(scope.row)"><i class="iImg"></i>修改</button>
									<button class="ucBtn ucBtn-info ucBtn-green" @click="handleClickDelete(scope.row)"><i class="iImg"></i>删除</button>
								</template>
							</el-table-column>
						</el-table>
						<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page="currentPage"
								:page-size="pagesize" 
								layout="prev, pager, next"
								:total="total_count">   
						</el-pagination>
						
						<ctms_contract_info @closecallinfo="closecallinfo" append-to-body v-if="centerDialogVisible" width="70%"></ctms_contract_info>
					</div>
				
			</el-tab-pane>
			<el-tab-pane label="&nbsp;&nbsp;&nbsp;&nbsp;采购合同&nbsp;&nbsp;&nbsp;&nbsp;" name="2">
				
					<div class="ucPanel ucPanel-table">
						
						<el-table :data="dataLists" highlight-current-row border style="width: 100%">
							<el-table-column type="selection" width="55px" align="center"/>
							<el-table-column label="序号"  width="78px;">
								<template v-slot="scope">
									{{ (currentPage-1)*pagesize+scope.$index+1 }}
								</template>
							</el-table-column>
							<el-table-column prop="cno" label="合同编号" ></el-table-column>
							<el-table-column prop="cname" label="合同名称"></el-table-column>
							<el-table-column prop="gysmc" label="承接单位" ></el-table-column>
							<el-table-column prop="amount" label="合同金额/ 元" ></el-table-column>
							<el-table-column prop="signDate" label="签订日期"></el-table-column>
							<el-table-column prop="gcjd" label="工程进度" ></el-table-column>
							<el-table-column prop="zcno" label="主体合同编号" ></el-table-column>
							<el-table-column fixed="right" label="操作" width="180px" >
								<template v-slot="scope" >
									<button class="ucBtn ucBtn-info ucBtn-green" @click="handleClickEdit(scope.row)"><i class="iImg"></i>修改</button>
									<button class="ucBtn ucBtn-info ucBtn-green" @click="handleClickDelete(scope.row)"><i class="iImg"></i>删除</button>
								</template>
							</el-table-column>
						</el-table>
						<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page="currentPage"
								:page-size="pagesize" 
								layout="prev, pager, next"
								:total="total_count">   
						</el-pagination>
					</div>
				
			</el-tab-pane>
		</el-tabs>
		<ctms_contract_edit @closecallinfo="closecallinfo" append-to-body="true" v-if="centerDialogVisible" width="70%" ></ctms_contract_edit>
    </div>
</template>
<script>
	import ctms_contract_edit from './ctms_contract_edit.vue'
	import {ElConfigProvider} from 'element-plus';
	import zhCn from 'element-plus/lib/locale/lang/zh-cn';
	import axios from 'axios';
	export default {
		name: 'ZhProvider',
		props: {
			msg: String
		},
		components:{
			[ElConfigProvider.name]: ElConfigProvider
		},
		setup(){
			let locale = zhCn
			return{
				locale
			}
		},
		data: function() {
			return {
				centerDialogVisible: false,
				dataList: [],
				dataLists:[],
				infoMod: "",
				editId: null,
				beginDate:null,
				endDate:null,
				activeName: '1',
				ctype:'1',
				cno:'',
				cname:'',
				amount:null,
				amount2:null,
				xmlbs:[],
				zys:null,
				gid:null,
				gysmc:null,
				person:[],
				xmfzr:null,
				gctz:null,
				xmly:null,
				zynr:null,
				ctypes: [
					{ ctype: '服务合同', value: '1' },
					{ ctype: '采购合同', value: '2' }
				],
				major:null,
				departments:[],
				isframe:[
					{stat:"是",value:'1'},
					{stat:"否",value:'2'}
				],
				isaward:[
					{award:"是",value:'1'},
					{award:"否",value:'2'}
				],
				stats: [
					{ stat: '正常', value: '1' },
					{ stat: '作废', value: '2' }
				],
				type:1,
				zy:null,
				currentPage:1, //初始页
				pagesize:10,    //    每页的数据
				total_count:0,
				file:null,
				deptId:null,
				shouldshow:false,
				putawayname:'收起',
				mark:'',
				serviceCount:0,
			    purchaseCount:0,
				serviceContractAmount: 0,
				purchaseContractAmount: 0
				
			}
		},
		created() {
			this.setDate();
			this.searchDepartment();
			this.searchMajors();
			this.getType();
			this.searchDepartment();
			// this.searchAllContract();
			this.fetchData();
		},
		methods: {
			handleClickEdit: function(data){
				this.infoMod = "edit";
				this.editId=data.id;
				this.centerDialogVisible = true;
			},
			handleClickDelete: function(data){
				this.$confirm('您确定要删除合同“'+data.cname+'”吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'}).then(()=>{
						this.deleteData(data.id);
					});
			},
			handleClickAdd: function(){
				this.infoMod = "add";
				this.centerDialogVisible = true;
			},
			handleClick: function(data){
				console.log(data)
				this.type=data.props.name;
				this.ctype=data.props.name;
				this.currentPage=1;
				this.pagesize=10;
				this.fetchData();
			},
			handleClickQuery: function(data){
				var req = {};
				req.cmd = "searchAllContract";
				
			},
			fetchData: function(){
				this.dataList = [];
				var req = {};
				req.cmd = "searchAllContractForInsert";
				req.ctype=this.ctype;
				if(this.gid==""||this.gid==null){
					req.gid=null;
				}else{
					req.gid=this.gid;
				}
				if(this.deptId==''||this.deptId==null){
					req.deptId = '';
				}else{
					req.deptId = this.deptId.join(',');
				}
				req.cno = this.cno;
				req.cname = this.cname;
				req.amount = this.amount;
				req.amount2 = this.amount2;
				req.xmlb = this.xmlb;
				req.department = this.department;
				req.gysmc = this.gysmc;
				req.xmfzr = this.xmfzr;
				req.gctz = this.gctz;
				req.xmly = this.xmly;
				req.zynr = this.zynr;
				req.zy = this.zy;
				req.start = (this.currentPage-1) * this.pagesize;
				req.count = this.pagesize;
				req.mark=this.mark;
				req.beginDate = this.beginDate;
				req.endDate = this.endDate;
				console.log(req.ctype)
				console.log(req.gid)
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res);
					if(this.ctype == 1){
						this.dataList = res.datas.result.contract;
					}else{
						this.dataLists = res.datas.result.contract;
					}
					this.total_count=res.datas.result.total_count;
					this.serviceCount = res.datas.total.serviceCount;
					this.purchaseCount = res.datas.total.purchaseCount;
					this.purchaseContractAmount = res.datas.total.purchaseContractAmount;
					this.serviceContractAmount = res.datas.total.serviceContractAmount;
				}, null, this);	
			},
			deleteData: function(id){
				this.dataList = [];
				var req = {};
				req.cmd = "deleteContract";
				req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					this.fetchData();
				}, null, this);	
			},
			closecallinfo: function() {
				this.centerDialogVisible = false;
				this.fetchData();
			},
			searchMajors: function(){
				this.zys = [];
				var req = {};
				req.cmd = "searchMajors";
				// req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result.majors);
					this.zys = res.datas.result.majors;
				}, null, this);	
			},
			searchDepartment:function(){
				this.departments = [];
				var req = {};
				req.cmd = "searchDepartment";
				req.type=2;
				// req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result.departments);
					this.departments = res.datas.result.departments;
				}, null, this);	
			},
			getType:function(){
				this.xmlbs = [];
				var req = {};
				req.cmd = "getType";
				// req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result.majors);
					this.xmlbs = res.datas.result.majors;
					console.log(this.xmlbs);
				}, null, this);	
			},
			handleClickImport: function(){
				this.$refs.fileRef.dispatchEvent(new MouseEvent('click'))
			},
			setDate:function() {
				const y = new Date().getFullYear();
				const f = y + '-01-01';
				const l = y + '-12-31';
				this.beginDate = f;
				this.endDate=l;
			},
			uploadContract: function(e) {
				this.file = e.target.files[0];
				var self = this;
				var req = {};
				req.cmd = "importContractExcel";
				console.log(this.file);
				this.$sknet.uploadFile(this.$skconf("iot.surl"), req,["upload_contract_file"], function(res) {
					if(res.reason=='导入成功'){
						this.$message({
							message:res.reason,
							type:'success'
						});
					}else{
						this.$message({
							message:res.reason,
							type:'error'
						});
					}
					this.fetchData();
				}, null, this);
			},
			importUsers: function() {
				
			},
			// download:function() {
			// 	axios.get('muban.xls',{
			// 		responseType:'blob',
			// 	}).then(response=>{
			// 		const url = window.URL.createObjectURL(new Blob([response.data]));            
			// 		const link = document.createElement('a');            
			// 		let fname = '导入模板.xls';            
			// 		link.href = url;            
			// 		link.setAttribute('download', fname);            
			// 		document.body.appendChild(link);
			// 		link.click();
			// 	}).catch(error=>{
			// 		console.log('error:'+JSON.stringify(error));
			// 	});
			// },
			handleSizeChange: function (size) {
				this.pagesize = size;
				console.log(this.pagesize)  //每页下拉显示数据
			},
			handleCurrentChange: function(currentPage){
				this.currentPage = currentPage;
				this.fetchData();
				console.log(this.currentPage)  //点击第几页
			},
			putaway:function() {
				if(this.putawayname=='收起'){
					this.shouldshow=true;
					this.putawayname='打开';
				}else{
					this.shouldshow=false;
					this.putawayname='收起';
				}
			}
		},
		watch: {
		},
		components: {
			ctms_contract_edit
		},
		mounted: function() {}
	}
</script>